import './App.css';

function App() {
  return (
    <div className="App">
      <div>
        <img src='https://res.cloudinary.com/dhpvia1ae/image/upload/v1736483041/logo_cswgvu.png' style={{
          objectFit: "cover",
          height: "60px"
        }} alt='KyrahStar' />
      </div>
    </div>
  );
}

export default App;
